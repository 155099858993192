<template>
  <div class="bingding-box">
    <div class="context-box">
      <div class="center-box">
        <input class="phone-input" maxlength="11" placeholder="请输入手机号" v-model="state.phone" type="text">
        <div class="verification-box">
          <input maxlength="6" placeholder="请输入验证码" v-model="state.code" type="text">
          <button v-show="state.isShow" @click="getCode">获取验证码</button>
          <div v-show="!state.isShow" >{{state.count}}秒后重试</div>
        </div>
        
        <div class="authorization" @click="binding">绑定当前企业一键登录</div>
        <div class="icps">{{icp}}</div>
        <div class="text">该产品不支持注册，您可拨打18069248732联系我们</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAccountCaptcha, boundPhone } from '@/apis/user'
import { reactive,ref } from 'vue'
import { notification } from 'ant-design-vue'
import { useStore } from 'vuex'
import { SET_USER_INFO } from '@/store/user/mutations-type'
import { useRouter } from 'vue-router'

export default {
  setup(){
    const store = useStore()
    const state = reactive({
      phone: '',
      code: '',
      isCode: false,
      icp: window.icp,
      count: 60,
      isShow: true
    })
    const router = useRouter()

    // 校验规则
    const pattern = /^1[3-9]\d{9}$/;
    // 获取验证码
    const getCode = async () => {
      if (isClear.value) {
        clearInterval(timer)
        return 
      }
      if (!pattern.test(state.phone)) {
        notification['error']({
          message: '提示',
          description: '手机号不能为空'
        })
        return
      }
      state.isCode = true
      const res = await getAccountCaptcha({
        phone: state.phone,
        code: 'MODIFY_MOBILE'
      })
      state.isShow = false;
      state.count = 60
      var timer = setInterval(() => {
        state.count--
        if (state.count === 0) {
          state.isShow = true
          return 
        }
      }, 1000);
      console.log(res);
    }
    // 是否清除
    const isClear = ref(false)

    // 绑定
    const binding = async () => {
      let a= localStorage.getItem('enterpriseId')
      console.log(a, 'a');
      console.log('state', state);
      if (!state.isCode) return
      const res = await boundPhone({
        enterpriseId: localStorage.getItem('enterpriseId'),
        phone: state.phone,
        note: state.code
      })
      if (res.code === 0) {
        const userInfo = { ...store.state.user.userInfo, mobile: state.phone }
        localStorage.setItem('yda-admin-userInfo', JSON.stringify(userInfo))
        store.commit(`user/${SET_USER_INFO}`, userInfo)
        isClear.value = true;
        router.push('/')
      } else {
        state.isShow = true;
        state.count = 60;
      }
      console.log(res);
    }

    return {
      getCode,
      binding,
      state
    }
  }
}
</script>

<style lang="less" scoped>
.bingding-box {
  width: 100%;
  height: 100%;
  background-color: #FBFBFC;
  display: flex;
  justify-content: center;
  align-items: center;
  .context-box {
    width: 474px;
    height: 437px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    .center-box {
      width: 320px;
      height: 327px;

      // 手机号
      .phone-input {
        border: 0;
        border-bottom: 1px solid #D7DBE0;
        width: 100%;
        height: 31px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        padding-bottom: 9px;
        margin-bottom: 53px;
        &:focus {
          outline:none;
        }
      }

      .verification-box {
        width: 100%;
        height: 31px;
        border-bottom: 1px solid #D7DBE0;
        padding-bottom: 9px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 52px;

        input {
          border: 0;
          
          &:focus {
            outline:none;
          }
        }

        button {
          border: 0;
          cursor: pointer;
          background-color: #fff;
        }
      }

      .authorization {
        width: 320px;
        height: 40px;
        background-color: #0A7BFF;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 40px;
        text-shadow: 0px 8px 10px rgba(208,24,55,0.2);
        text-align: center;
        margin-bottom: 75px;
        cursor: pointer;
      }

      .icps {
        width: 100%;
        height: 21px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 21px;
        // background-color: pink;
        text-align: center;
        margin-bottom: 2px;
      }

      .text {
        width: 100%;
        height: 21px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 21px;
        text-align: center;
      }
    }
  }
}
</style>